<template>
  <div class="agent-wrap">
    <Header :title="$t('agent.manageResource')" :subTitle="$t('common.edit')">
      <ChangeAvatar :headPhoto="userInfo.headPhoto" :setHeadPhoto="setHeadPhoto" />
      <ChangeBg :userId="userId" />
    </Header>
    <div class="agent-info-box">
      <!-- 昵称 -->
      <div class="info-item" @click="editNickName">
        <div class="item-left"><span class="red">*</span>{{ $t('userDetail.nickName') }}</div>
        <div class="item-right">
          <div> {{ userInfo.nickname }}</div>
          <div>
            <img src="@/assets/img/icon/arrow.svg" />
          </div>
        </div>
      </div>
      <!-- 位置 -->
      <div class="info-item" @click="eidtLocation('position')">
        <div class="item-left"><span class="red">*</span>{{ $t('userDetail.location') }}</div>
        <div class="item-right">
          <div>{{ (position && (`${position.province.name}/${position.city.name}/${position.area.name}`) ||
            $gbUtils.getAllPositionName(userInfo.location)) }}</div>
          <div><img src="@/assets/img/icon/arrow.svg" /></div>
        </div>
      </div>
      <!-- Telegram -->
      <div class="info-item" @click="editTelegram">
        <div class="item-left">Telegram</div>
        <div class="item-right">
          <div>{{ userInfo.telegram }}</div>
          <div><img src="@/assets/img/icon/arrow.svg" /></div>
        </div>
      </div>
      <!-- 微信 -->
      <div class="info-item" @click="editWechat">
        <div class="item-left">{{ $t('userDetail.wechat') }}</div>
        <div class="item-right">
          <div>{{ userInfo.wechat }}</div>
          <div><img src="@/assets/img/icon/arrow.svg" /></div>
        </div>
      </div>
      <!-- 生日 -->
      <div class="info-item" @click="editAge">
        <div class="item-left">{{ $t('userDetail.age') }}</div>
        <div class="item-right">
          <div>{{ userInfo.age }}</div>
          <div><img src="@/assets/img/icon/arrow.svg" /></div>
        </div>
      </div>
      <!-- 身高体重 -->
      <div class="info-item" @click="editHeightWeight">
        <div class="item-left">{{ $t('userDetail.heightAndWeight') }}</div>
        <div class="item-right">
          <div><span v-if="userInfo.height">{{ userInfo.height }}cm/{{ userInfo.weight }}kg </span>
            </div>
          <div><img src="@/assets/img/icon/arrow.svg" /></div>
        </div>
      </div>

      <!-- 自我介绍 -->
      <div class="info-item" @click="eidtDescription">
        <div class="item-left">{{ $t('userDetail.selfDescription') }}</div>
        <div class="item-right">
          <div>{{ userInfo.description }}</div>
          <div><img src="@/assets/img/icon/arrow.svg" /></div>
        </div>
      </div>
      <!-- 行业 -->
      <div class="info-item" @click="editJob">
        <div class="item-left">{{ $t('userDetail.career') }}</div>
        <div class="item-right">
          <div>{{ userInfo.job }}</div>
          <div><img src="@/assets/img/icon/arrow.svg" /></div>
        </div>
      </div>
      <!-- 气质类型 -->
      <div class="info-item" @click="editTemperament">
        <div class="item-left">{{ $t('userDetail.temperamentType') }}</div>
        <div class="item-right">
          <div>{{ userInfo.temperament }}</div>
          <div><img src="@/assets/img/icon/arrow.svg" /></div>
        </div>
      </div>
      <!-- 经常出没 -->
      <div class="info-item" @click="eidtLocation('often', 3, true)">
        <div class="item-left">{{ $t('userDetail.offen') }}</div>
        <div class="item-right">
          <div>
            <span v-for="(item, index) in often" :key="index">
              {{ item }}
              <span v-if="index + 1 !== often.length">、</span>
            </span>
          </div>
          <div><img src="@/assets/img/icon/arrow.svg" /></div>
        </div>
      </div>
    </div>
    <div class="save-btn">
      <div @click.stop="updateAgent">{{ $t('common.confirm') }}</div>
    </div>

    <!--昵称编辑-->
    <TextInput v-model="showNickName" :title="inputObj.title" :initMsg="inputObj.initMsg" @close="closeNickNamePop"
      @getMessage="getNickName" />

    <!--联系方式编辑-->
    <TextInput v-model="showTelegram" :title="inputObj.title" :initMsg="inputObj.initMsg" @close="closeTelegramPop"
      @getMessage="getTelegram" />

    <!--微信-->
    <TextInput v-model="showWechat" :title="inputObj.title" :initMsg="inputObj.initMsg" @close="closeWechatPop"
      @getMessage="getWechat" />

    <!--生日编辑-->
    <AgePicker v-model="showAge" @getSelected="getAge" @close="closeAgePop" />

    <!--身高体重-->
    <HwPicker v-model="showHeightWeight" @getSelected="getHeightWeight" @close="closeHeightWeightPop" />


    <!--自我介绍-->
    <TextInput v-model="showDescription" :title="inputObj.title" :initMsg="inputObj.initMsg"
      :inputType="inputObj.inputType" :placeholder="inputObj.placeholder" :rows="inputObj.rows"
      :maxlength="inputObj.maxlength" @close="closeDescriptionPop" @getMessage="getDescription" />

    <!--行业-->
    <LabelPop v-model="showJob" :title="LableObj.title" :labels="LableObj.labels" :limit="LableObj.limit"
      @getSelectedList="getJob" @close="closeJobPop" />

    <!--气质类型-->
    <LabelPop v-model="showTemperament" :title="LableObj.title" :labels="LableObj.labels" :limit="LableObj.limit"
      @getSelectedList="getTemperament" @close="closeTemperamentPop" />
    <router-view class="child-router-view"></router-view>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import ChangeBg from '../../components/ChangeBg.vue';
import ChangeAvatar from '@/components/ChangeAvatar.vue';
import TextInput from '../../components/user/TextInput.vue'
import AgePicker from '../../components/user/AgePicker.vue'
import HwPicker from '../../components/user/HwPicker.vue'
import LabelPop from '../../components/user/LabelPop.vue'
import { queryAgentInfoById } from '@/service/agent'
import bus from '../../utils/bus'
import { editAgent } from '@/service/agent'
import { getLables } from '@/service/base'
import { setTimeout } from 'timers'
export default {
  name: 'AgentEdit',
  components: { Header, ChangeBg, TextInput, AgePicker, HwPicker, LabelPop, ChangeAvatar },
  data() {
    return {
      title: '修改',
      userId: null,
      avatarList: [],
      userInfo: {},
      showNickName: false,
      showTelegram: false,
      showWechat: false,
      showAge: false,          // 年龄
      showHeightWeight: false, // 身高体重
      showDescription: false,  // 自我介绍
      showJob: false,          // 行业
      showTemperament: false,  // 气质类型
      inputObj: {
        title: '新增',
        initMsg: '',
        rows: 1,
        placeholder: '',
        inputType: 'text',
        maxlength: 30
      },
      LableObj: {
        title: '',
        labels: [],
        limit: 1
      },
      jobLabels: ['自由职业', '护士', '幼师', '医美'],  // 行业标签
      temperamentLabels: [], //气质类型标签
      position: '', // 位置
      often: [], // 常出没
    }
  },
  methods: {
    init() {
      if (this.userId) {
        this.getAgentInfo()
        this.getLabelList()
      }
    },
    async getLabelList() {
      const resp = await getLables('sc_label_job')
      if (!resp.success) return
      this.jobLabels = resp.data

      const resp1 = await getLables('sc_label_temperament')
      if (!resp1.success) return
      this.temperamentLabels = resp1.data
    },
    async getAgentInfo() {
      const resp = await queryAgentInfoById({userId: this.userId})
      if (resp.success) {
        this.userInfo = resp.data
        if (this.userInfo.often) {
          this.often = this.userInfo.often.split(',')
        }
      } else {
        console.log('getAgentInfo Error, errMsg=', resp.msg)
        this.$toast('获取代理资源用户信息错误')
      }
    },
    setHeadPhoto(headPhoto) {
      this.userInfo.headPhoto = headPhoto;
    },
    async updateAgent() {
      if (!this.userInfo.headPhoto) {
        this.$toast(this.$t('agent.emptyAvatar'))
        return
      }
      if (!this.userInfo.nickname) {
        this.$toast(this.$t('agent.emptyNickname'))
        return
      }
      if (!this.userInfo.location) {
        if (!this.position.area?.code) {
          this.$toast(this.$t('agent.emptyLocation'))
          return
        }
      }
      const telegram = this.userInfo.telegram
      const wechat = this.userInfo.wechat
      if (this.$gbUtils.isEmptyString(telegram) && this.$gbUtils.isEmptyString(wechat)) {
        this.$toast(this.$t('userDetail.tgAndWechatNotEmpty'))
        return
      }
      if (this.position) {
        this.userInfo.location = this.position.area.code
      }
      if (this.often && this.often.length > 0) {
        this.userInfo.often = this.often.join()
      }
      this.userInfo.userId = this.userId
      const resp1 = await editAgent(this.userInfo)
      if (resp1.success) {
        this.$toast(this.$t('common.editSuccess'))
        setTimeout(() => {
          this.$router.go(-1)
        }, 500)
      }
    },
    editNickName() {
      this.inputObj.title = this.$t('userDetail.nickName')
      this.inputObj.placeholder = this.$t('userDetail.nickNameHolder')
      this.showNickName = true
      this.inputObj.initMsg = this.userInfo.nickname
    },
    getNickName(val) {
      this.userInfo.nickname = val
    },
    editTelegram() {
      this.inputObj.title = 'Telegram'
      this.inputObj.placeholder = this.$t('userDetail.telegramHolder')
      this.showTelegram = true
      this.inputObj.initMsg = this.userInfo.telegram
    },
    editWechat() {
      this.inputObj.title = this.$t('userDetail.wechat')
      this.inputObj.placeholder = this.$t('userDetail.wechatHolder')
      this.showWechat = true
      this.inputObj.initMsg = this.userInfo.wechat
    },
    getTelegram(val) {
      this.userInfo.telegram = val
    },
    getWechat(val) {
      this.userInfo.wechat = val
    },
    eidtLocation(addressKey, areaLevel = 0, multiple = false, maxCount = 3) {
      const path = `agentEdit/editAddress?addressKey=${addressKey}&areaLevel=${areaLevel}&multiple=${multiple}&maxCount=${maxCount}`
      this.$gbUtils.handleToPage(path)
    },
    editAge() {
      this.showAge = true
    },
    getAge(val) {
      this.userInfo.age = val.join()
    },
    editHeightWeight() {
      this.showHeightWeight = true
    },
    getHeightWeight(val) {
      this.userInfo.height = val[0].substring(0, 3)
      this.userInfo.weight = val[1].substring(0, 2)
    },
    getLabel(val) {
      this.userInfo.label = val.join()
    },
    eidtDescription() {
      this.inputObj.title = this.$t('userDetail.selfDescription')
      this.inputObj.placeholder = this.$t('userDetail.selfDescriptionHolder')
      this.showDescription = true
      this.inputObj.inputType = 'textarea'
      this.inputObj.maxlength = 150
      this.inputObj.rows = 5
      if (this.userInfo.description !== '') {
        this.inputObj.initMsg = this.userInfo.description
      }
    },
    getDescription(val) {
      this.userInfo.description = val
    },
    editJob() {
      this.LableObj.title = this.$t('userDetail.career')
      this.LableObj.labels = this.jobLabels
      this.showJob = true
    },
    getJob(val) {
      this.userInfo.job = val[0]
    },
    editTemperament() {
      this.LableObj.title = this.$t('userDetail.temperamentType')
      this.LableObj.labels = this.temperamentLabels
      this.LableObj.limit = 3
      this.showTemperament = true
    },
    getTemperament(val) {
      console.log('getTemperament val==', val)
      this.userInfo.temperament = val.join()
    },
    closeNickNamePop() {
      this.clearInputObj()
      this.showNickName = false
    },
    closeTelegramPop() {
      this.clearInputObj()
      this.showTelegram = false
    },
    closeWechatPop() {
      this.clearInputObj()
      this.showWechat = false
    },
    closeAgePop() {
      this.showAge = false
    },
    closeHeightWeightPop() {
      this.showHeightWeight = false
    },
    closeLabelPop() {
      this.showLabel = false
    },
    closeDescriptionPop() {
      this.clearInputObj()
      this.showDescription = false
    },
    closeJobPop() {
      this.showJob = false
    },
    closeTemperamentPop() {
      this.clearLableObj()
      this.showTemperament = false
    },
    clearInputObj() {
      this.inputObj.title = ''
      this.inputObj.placeholder = ''
      this.inputObj.initMsg = ''
      this.inputObj.inputType = ''
      this.inputObj.rows = 1

    },
    clearLableObj() {
      this.LableObj.title = ''
      this.LableObj.labels = []
      this.LableObj.limit = 1
    },
    clearData() {
      this.userInfo = []
      this.avatarList = []
      this.often = []
      this.position = ''
    }
  },
  created() {
    this.userId = this.$route.query.userId
    console.log('created userId=', this.userId)
    this.init()
  },
  mounted() {
    console.log('mounted ..')
    bus.$on('getSelectAreas', (key, data) => {
      if (key === 'often') { // 常出没城市后台没有存code,只存了name ，特殊处理
        let cityName = []
        data.forEach(item => {
          cityName.push(item.city.name)
        })
        this.$data[key] = cityName
      } else {
        this.$data[key] = data
      }
    })
  },
  beforeDestroy() {
  }
}

</script>


<style lang="scss" scoped>
.agent-wrap {
  background: #14151e;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;

  .back-title {
    margin: 0 10px;
  }

  .agent-info-box {
    padding: 20px;
    width: 100%;

    >:first-child {
      border-top: 1px solid #3D3F51;
    }

    .info-item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 18px 0;
      border-bottom: 1px solid #3D3F51;
      font-size: 14px;

      div {
        display: flex;
        align-items: center;
      }

      .item-left {
        min-width: 65px;
      }

      .item-right {
        div {
          margin-left: 10px;
        }

        div,
        span {
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }

  .save-btn {
    width: 200px;
    height: 38px;
    background: #8A73D0;
    border-radius: 4px;
    text-align: center;
    line-height: 38px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 5px;
    margin-bottom: 150px;
  }

  .red {
    color: #EF08E4;
  }
}
</style>